import { JSONContent } from '@tiptap/react';

import { useEditor } from '@/modules/editor/context';
import { getNodeSources } from '../../findings/editor/extensions/utils/getNodeSources';
import { SourceCount, useGroupedNoteSources } from '../findings/source';
import { useNote } from './note';

const renderMarks = (
  text?: string,
  marks?: {
    [key: string]: any;
    type: string;
    attrs?: Record<string, any> | undefined;
  }[],
): string => {
  if (!text) {
    return '';
  }

  if (!marks) {
    return text;
  }

  return marks.reduce((html, mark) => {
    switch (mark.type) {
      case 'bold': {
        return `<b>${html}</b>`;
      }
      case 'italic': {
        return `<em>${html}</em>`;
      }
      case 'strike': {
        return `<s>${html}</s>`;
      }
      default: {
        return html;
      }
    }
  }, text);
};

const renderHTML = (
  nodes?: JSONContent[],
  noteSources: SourceCount[] = [],
  includeParagraph = true,
): string => {
  if (!nodes) {
    return '';
  }

  const html = nodes.map((node) => {
    const nodeSources = getNodeSources(node);

    switch (node.type) {
      case 'paragraph': {
        if (includeParagraph) {
          return `<p>${renderHTML(node.content, noteSources)}</p></br>`;
        }

        return renderHTML(node.content, noteSources);
      }
      case 'text': {
        return renderMarks(node.text, node.marks).replaceAll('\n', '</br>');
      }
      case 'heading': {
        return `<p><h${node.attrs?.level || 2}>${renderHTML(
          node.content,
          noteSources,
        )}</h${node.attrs?.level || 2}> ${nodeSources
          .map((source) => `<a href="${source}">(source)</a>`)
          .join(' ')}</p>`;
      }
      case 'citationInline': {
        return `<a href="${node.attrs?.sourceUrl[0]}">${renderHTML(
          node.content,
          noteSources,
        )}</a>`;
      }
      case 'bulletList': {
        return `<ul>${renderHTML(node.content, noteSources)}</ul>`;
      }
      case 'listItem': {
        return `<li>${renderHTML(node.content, noteSources)}</li>`;
      }
      case 'image': {
        return `<img src="${node.attrs?.src}" alt="${node.attrs?.src}" /></br>`;
      }
      case 'finding': {
        const sources = node.attrs?.url ? [node.attrs.url] : [];
        const allSources = noteSources
          .map((v, index) => {
            if (sources.includes(v.url)) {
              return {
                label: (index + 1).toString(),
                url: v.url,
              };
            }
            return false;
          })
          .filter(Boolean) as { label: string; url: string }[];

        const findingType = node.attrs?.type ? node.attrs.type : '';
        if (findingType === 'image') {
          return `<img src="${node.attrs?.data.imageUrl}" alt="${
            node.attrs?.data.imageUrl
          }" />${allSources
            .map((source) => `<a href="${source.url}">(source)</a>`)
            .join(' ')}</br>`;
        }

        return `<p>“<em>${renderHTML(
          node.content,
          noteSources,
        )}</em>” ${allSources
          .map((source) => `<a href="${source.url}">(source)</a>`)
          .join(' ')}</p>`;
      }
      case 'blockquote': {
        return `<p>“<em>${renderHTML(
          node.content,
          noteSources,
          false,
        )}</em>” ${nodeSources
          .map((source) => `<a href="${source}">(source)</a>`)
          .join(' ')}</p>`;
      }
      default: {
        return '';
      }
    }
  });

  return html.join('');
};

const renderCitations = (metadata?: any) => {
  if (!metadata || !metadata.citations || metadata.citations.length === 0) {
    return '';
  }

  return `
    </br>
    </br>
    <h2>References</h2>
    </br>
    <ol>
      ${metadata.citations
        .map(
          (citation: { url: string; title: string }) =>
            `<li><a href="${citation.url}">${citation.title}</a></li>`,
        )
        .join('')}
    </ol>
  `;
};

export const useNoteHTML = (noteId: number): string => {
  const note = useNote(noteId);
  const noteSources = useGroupedNoteSources();
  const editor = useEditor();
  const content = editor.editor?.getJSON();

  if (!note || !content) {
    return '';
  }

  const { featureImageUrl } = note;
  return `<h1>${note.title}</h1>${
    note.metadata?.description
      ? `</br><p>${note.metadata?.description}</p>`
      : ''
  }</br></br>${
    featureImageUrl ? `<img src="${featureImageUrl}" /></br></br>` : ''
  }${renderHTML(content.content, noteSources)}${renderCitations(
    note.metadata,
  )}`;
};
